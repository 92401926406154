import { h } from 'preact';
import Container from '../Container/Container';
import * as classes from './LoadingSpinner.module.css';

const LoadingSpinner = () => {
    return (
        <Container>
            <div className={classes.spinner}>Loading...</div>
        </Container>
    );
};

export default LoadingSpinner;
