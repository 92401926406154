.footer {
    display: flex;
    padding-bottom: var(--grid-gap);
    padding-top: calc(var(--grid-gap) * 2);
    width: 100%;
}

.footerList {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    align-content: center;
    padding-left: 0;
    margin: 0;
    width: 100%;
}

.className2 {
    padding-left: var(--page-gutter);
}

@media (width >= 700px) {
    .footerList {
        flex-direction: row;
        justify-content: space-between;
    }
}
