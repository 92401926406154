.header {
    display: flex;
    place-content: center space-between;
    padding: 10px var(--page-gutter) var(--grid-gap) var(--page-gutter);
    margin: 0 auto;
    max-width: var(--page-max-width);
    width: 100%;
}

/* Header */
.headerImg {
    width: 200px;
    height: 100%;
    margin-left: -10px;
}

@media (width >=600px) {
    .headerImg {
        width: 240px;
    }
}

.headerList {
    list-style-type: none;
    padding-left: 0;
    text-align: center;
}

.headerList a {
    color: inherit;
    text-decoration: none;
}

@media (width >=600px) {
    .headerList {
        display: flex;
    }

    .headerList li:not(:last-child) {
        margin-right: 40px;
    }
}

/* Burger Menu */
.hamburg {
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding-left: var(--page-gutter);
    padding-right: var(--page-gutter);
    position: relative;
    z-index: 1;
}

@media (width >=600px) {
    .hamburg {
        display: none;
    }
}

.hamburg:active .line:nth-child(2) {
    opacity: 0;
}

.hamburg:active .line:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

.hamburg:active .line:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}

.headerNav {
    position: fixed;
    inset: 0;
    background: var(--page-background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: var(--z-middle);
}

@media (width >=600px) {
    .headerNav {
        display: block;
        position: relative;
        background: unset;
    }

    .navItem {
        margin: 16px 0;
    }

    .headerNav.active {
        left: 0;
    }
}
